  import {useState, useEffect} from 'react'
  import './App.css';
  import {
  Switch,
  Route, Link, useHistory} from "react-router-dom";
  import Dashboard from './routes/Dashboard'
  import Home from './routes/Home'
  import Login from './routes/Login'
  import Register from './routes/Register'
  import logo from './assets/wct_logo.png'
  import Axios from 'axios'

function App() {


  const [loggedIn, setLoggedIn] = useState(false);
  const [error, setError] = useState(false)
  const [registered, setRegister] = useState(false)

  let history = useHistory()


  useEffect(() => {
      if (!loggedIn) {
        history.push('/register')
      }

      Axios.get('https://admin-api.wctland.com/').then((res) => {
        setError(false)
    }).catch((err) => {
        console.log(err)
        setError(true)
    })

    }, [error, history, loggedIn, registered])

    // function Logout() {
    //   setLoggedIn(false)
    //   history.push('/')
    // }

    function handleClick() {
      history.push('/register')
    }



  return (
    <div>
          {error ? (<><div className="error-bg"></div><div className="error-cont"><div className="error"><p>Sorry, the server is currently overloaded. Please try again later.</p></div></div></>) : (null)}
      <div className="navbar-container">
        <img onClick={handleClick} className="logo" alt='logo' src={logo} />
      <ul>
        {/* {loggedIn ? (<li><Link to='/dashboard'>Dashboard</Link></li>) : (<li><Link to='/login'>Login</Link></li>)} */}
        {loggedIn ? (null) : (<li><Link to='/register'>Register</Link></li>)}
        {/* {loggedIn ? (<li onClick={Logout}>Logout</li>) : (null)} */}

      </ul>
      </div>


     <Switch>

      <Route exact path='/'>
        <Home loggedIn={loggedIn} setLoggedIn={setLoggedIn}/>
      </Route>

      <Route path='/dashboard'>
      <Dashboard />
      </Route>

      <Route path="/login">
        <Login loggedIn={loggedIn} setLoggedIn={setLoggedIn}/>
      </Route>

      <Route path="/register">
        <Register registered={registered} setRegister={setRegister}/>
      </Route>


      <Route path="/forgotpassword">
        <div style={{paddingTop: '100px'}}>
          Contact admin.
        </div>
      </Route>

     </Switch>
    </div>
  );
}

export default App;
