import {useState} from 'react'
import Axios from 'axios'

export default function Register({registered, setRegister}) {


    const [regData, setRegData] = useState({
        email: '',
        password: '',
        full_name: '',
        agency: '',
        phone: ''
    })



    function handleSubmit(e) {
        e.preventDefault();

        if (regData.email && regData.full_name && regData.agency && regData.phone) {
            Axios.post('https://admin-api.wctland.com/register', {
                email: regData.email,
                password: regData.password,
                full_name: regData.full_name,
                agency: regData.agency.replace(/[^a-z\d\s]+/gi, ""),
                phone: regData.phone
            }).then((err, res) => {
                if (err) throw err;
                console.log(res)
            })
            setRegister(true)
        } else console.log('Complete form')


    }


    return(
        <div className="form-container">
            <h2>Registration</h2>
           {registered ? (
               <h5>Your details has been sent to the admin for verification, once approved you will get a custom virtual tour link and its password to host a live guided tour through email. Please contact your admin for any enquiries.</h5>
           ) : (
            <form onSubmit={e => handleSubmit(e)} method="POST">
            <div className="inputContainer">
                <label className="inputTitle"><p>Email</p></label>
                <label className="inputSeparator"><p>:</p></label>
                <input type="email" value={regData.email} onChange={e => setRegData({...regData, email: e.target.value})}/>
            </div>
            <div className="inputContainer">
                <label className="inputTitle"><p>Full Name</p></label>
                <label className="inputSeparator"><p>:</p></label>
                <input type="text" value={regData.full_name} onChange={e => setRegData({...regData, full_name: e.target.value})}/>
            </div>
            {/* <div className="inputContainer">
                <label className="inputTitle"><p>Agency</p></label>
                <label className="inputSeparator"><p>:</p></label>
                <input type="text" value={regData.agency} onChange={e => setRegData({...regData, agency: e.target.value})}/>
            </div> */}
                        <div className="inputContainer">
                <label className="inputTitle"><p>Agency</p></label>
                <label className="inputSeparator"><p>:</p></label>
                <select placeholder="Agency"value={regData.agency} onChange={e => setRegData({...regData, agency: e.target.value})}>
            <option style={{display: 'none'}}>Select Agency</option>
  <option>WCTL</option>
  <option>Affirm Plus</option>
  <option>Arden Estates</option>
  <option>Atracs/ECS</option>
  <option>Believe Realty</option>
  <option>Big Plus</option>
  <option>BPG</option>
  <option>Centricity</option>
  <option>CBD Properties (Puchong)</option>
  <option>Chester KL (BK Bandaraya)</option>
  <option>Chong Seng</option>
  <option>CiD</option>
  <option>Citrus Properties</option>
  <option>City Real</option>
  <option>CityLink</option>
  <option>CNH</option>
  <option>Cornerstone Xstate</option>
  <option>CTG</option>
  <option>CTLS</option>
  <option>Dama Property</option>
  <option>Dutama Properties</option>
  <option>Duxton</option>
  <option>E Trend</option>
  <option>Esprit</option>
  <option>EUM</option>
  <option>Focus</option>
  <option>FLP Realty</option>
  <option>Forward</option>
  <option>Full Homes</option>
  <option>Gather Properties</option>
  <option>GIC</option>
  <option>Global Link </option>
  <option>Goldmine</option>
  <option>GS Realty</option>
  <option>GT Nelson</option>
  <option>Hartamas Real Estate</option>
  <option>IBI Realty</option>
  <option>iDream Realty</option>
  <option>IPG</option>
  <option>IQI</option>
  <option>IWP</option>
  <option>J Maison</option>
  <option>Jack Lim Realty</option>
  <option>Juste Land</option>
  <option>Kingsman Realty</option>
  <option>KK Land Properties</option>
  <option>Knight World</option>
  <option>Landserve</option>
  <option>Leaders</option>
  <option>Maxland</option>
  <option>Maxxan Realty</option>
  <option>Metro Homes</option>
  <option>Midas</option>
  <option>MLP</option>
  <option>Multilink</option>
  <option>Nawawi Tie Leung</option>
  <option>New Bob Realty</option>
  <option>Nexsgen</option>
  <option>One WSM Property</option>
  <option>Oriental</option>
  <option>Phoenix Plus</option>
  <option>Pillarz</option>
  <option>Property Link</option>
  <option>Propmex </option>
  <option>Propnex</option>
  <option>Quinco </option>
  <option>RE Finders</option>
  <option>Reapfield</option>
  <option>Renstar Properties</option>
  <option>Revoll Realty</option>
  <option>Royce Properties</option>
  <option>RTS</option>
  <option>Simon Realty</option>
  <option>Solid Real Estate Consultants</option>
  <option>SouFun</option>
  <option>Star Property</option>
  <option>Starhill</option>
  <option>The Great Haus</option>
  <option>The Roof Realty</option>
  <option>Torive Properties</option>
  <option>Total Realty</option>
  <option>Vivahomes</option>
  <option>Wallstreet</option>
  <option>Wereg</option>
  <option>Wikiprop</option>
  <option>Wingfield</option>
            </select>
            </div>
            <div className="inputContainer">
                <label className="inputTitle"><p>Phone Number</p></label>
                <label className="inputSeparator"><p>:</p></label>
                <input type="text" value={regData.phone} onChange={e => setRegData({...regData, phone: e.target.value})}/>
            </div>

            <button className="submit">Register</button>
            </form>
            )}

        </div>
    )
}