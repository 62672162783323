import {Link, useHistory} from "react-router-dom";

export default function Login({setLoggedIn}) {

    let history = useHistory()

    
    function handleSubmit(e) {
        e.preventDefault();
        setLoggedIn(true)
        history.push('/')
    }



    return(
        <div className="form-container">
            <h2>Agent Log In</h2>

            <form onSubmit={e => handleSubmit(e)}>
            <div className="inputContainer">
                <label className="inputTitle"><p>Username</p></label>
                <label className="inputSeparator"><p>:</p></label>
                <input type="text"/>
            </div>
            <div className="inputContainer">
                <label className="inputTitle"><p>Password</p></label>
                <label className="inputSeparator"><p>:</p></label>
                <input type="password"/>
            </div>


            <Link to='/forgotpassword'><p>Forgot Password?</p></Link>

            <button className="submit">Login</button>
            </form>

        </div>
    )
}